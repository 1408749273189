import React from "react"
import PropTypes from "prop-types"
import Index from "./index"
import Show from "./show"

class chatBody extends React.Component {
  state = {
  };

  changePage(pageType) {
    this.props.changePageType(pageType);
  }

  changeRoomId(roomId) {
    this.props.changeRoomId(roomId);
  }

  render() {
    let CONTENT = this.props.pageType == 'index' ?
      <Index
        baseURI={this.props.baseURI}
        uid={this.props.uid}
        changePageType={(pageType) => { this.changePage(pageType); }}
        changeRoomId={(roomId) => { this.changeRoomId(roomId); }}
      /> :
      <Show
        baseURI={this.props.baseURI}
        uid={this.props.uid}
        dmRoomId={this.state.dmRoomId}
        roomId={this.props.roomId}
      />;

    return <div className="chat-body">
      {CONTENT}
    </div>;
  }
}

chatBody.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  pageType: PropTypes.string,
  changePageType: PropTypes.func,
  changeRoomId: PropTypes.func,
  roomId: PropTypes.string,
};
export default chatBody
