import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

class threadForm extends React.Component {
  state = {
    imageBlob: "",
    imageFormData: null,
    disabled: true,
    content: "",
    isUploading: false,
  };

  postThreads() {
    this.setState({
      disabled: true,
      isUploading: true,
    });

    let formData = this.state.imageFormData
      ? this.state.imageFormData
      : new FormData();
    formData.append("post[uid]", this.props.uid);
    formData.append("post[content]", this.state.content);
    formData.append("post[pid]", this.props.thread.public_id);

    const sendForm = () => {
      const url = `${this.props.baseURI}api/v1/thread_replies/post_reply`;
      const data = formData;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(url, data, config)
        .then((response) => {
          this.setState({
            content: "",
            imageBlob: "",
            imageFormData: {},
            isUploading: false,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ disabled: true });
        });
    };
    sendForm();
  }

  changeContent(e) {
    let disabled = true;
    const text = e.currentTarget.value;
    // 空文字（未入力、スペースのみ、改行のみ）を判定
    if (!text || !text.match(/\S/g)) {
      disabled = true;
    } else {
      disabled = false;
    }
    this.setState({
      content: e.currentTarget.value,
      disabled: disabled,
    });
  }

  setLoading() {
    const status = document.getElementById("status");
    if (status && status.dataset.loading == "false") {
      status.dataset.loading = "true";
    }
  }

  setImage(e) {
    // 画像をプレビュー
    const generatePreview = () => {
      const file_list = e.currentTarget.files;
      if (!file_list) return;
      const file = file_list[0];
      if (!file) return;

      // Blob URL Scheme を生成
      return window.URL.createObjectURL(file);
    };

    // フォームを作成
    const createFormData = () => {
      const file_list = e.currentTarget.files;
      if (!file_list) return;
      const file = file_list[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("post[image]", file);
      return formData;
    };

    this.setState({
      imageBlob: generatePreview(),
      imageFormData: createFormData(),
    });
  }

  removeImage() {
    this.setState({
      imageBlob: "",
      imageFormData: {},
    });
  }

  render() {
    return (
      <div className="thread-reply-form">
        <a
          className="user-icon"
          href="/mypage/profiles"
          style={{ backgroundImage: `url(${this.props.userIcon})` }}
        ></a>
        <div className="post-box">
          <textarea
            className="textarea"
            placeholder="返信を投稿"
            onChange={this.changeContent.bind(this)}
            value={this.state.content}
          ></textarea>
          <div className="submit-area">
            <label className="image-button" aria-label="画像添付ボタン">
              <i
                className={`fas fa-fw ${
                  this.state.isUploading ? "fa-spinner fa-spin" : "fa-image"
                }`}
                aria-hidden
              ></i>
              <input
                type="file"
                accept="image/png, image/jpeg, image/gif, image/webp"
                className="input"
                onChange={this.setImage.bind(this)}
                disabled={this.state.isUploading}
              />
            </label>
            <button
              className="button"
              onClick={this.postThreads.bind(this)}
              disabled={this.state.disabled}
            >
              返信
            </button>
          </div>
          <div
            className={`image-preview-area ${
              this.state.imageBlob ? "show" : ""
            }`}
          >
            <div
              className="image-preview"
              style={{ backgroundImage: `url(${this.state.imageBlob})` }}
            >
              <div
                className="reset-button"
                onClick={this.removeImage.bind(this)}
              >
                <i className="fas fa-times" aria-hidden></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

threadForm.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  userIcon: PropTypes.string,
  thread: PropTypes.object,
};
export default threadForm;
