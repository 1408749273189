import React from "react"
import PropTypes from "prop-types"

class chatHeadIndex extends React.Component {
  render() {
    return <React.Fragment>
      <div className="left-content"></div>
      <div className="center-content">
        <p className="title">メッセージ一覧</p>
      </div>
      <div className="right-content">
        <div id="closeModal" className="close-button" onClick={closeModal.bind(this)}><i className="fas fa-times"></i></div>
      </div>
    </React.Fragment>;
  }
}

chatHeadIndex.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
};
export default chatHeadIndex
