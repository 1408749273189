import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class forumComments extends React.Component {
  state = {
    comments: [],
  };

  fetchComments() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}api/v1/forums/fetch_comments`,
      params: {
        forum: this.props.forumId,
      }
    }).then((response) => {
      this.setState({
        comments: response.data.forum_comments
      });
    });
  }

  checkLoadingStatus() {
    const forumData = document.getElementById('forumData');
    if (forumData.dataset.loading == 'true') {
      forumData.dataset.loading = 'false'
      this.fetchComments();
    }
  }

  setMessage(text) {
    // 改行タグを<br />に置き換える
    let returnText = this.stripTags(text, '<br>').replace(/\r?\n/g, '<br />')

    // リンクが含まれたテキストにaタグを追加する
    return returnText = this.autoLink(returnText)
  }

  stripTags(text, allowed) {
    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    return text.replace(tags, ($0, $1) => (allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''))
  }

  autoLink(text) {
    const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    const regexp_makeLink = (all, url, h, href) => {
      const target = href.indexOf('beforester.net') > 0 ? '' : 'target = "_blank"';
      if (href.toLowerCase().indexOf('javascript:') > 0) return url;
      return '<a href="h' + href + '" class="link-color under-line" ' + target + 'rel="nofollow">' + url + '</a>';
    }

    return text.replace(regexp_url, regexp_makeLink);
  }

  componentDidMount() {
    this.fetchComments();

    // 1秒に1回チェック
    this.timerID = setInterval(() => {
      this.checkLoadingStatus();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    let comments = <p>まだコメントがありません</p>;
    if (this.state.comments) {
      comments = this.state.comments.map((comment, key) => <div className="comment" key={key}>
        <a href={`/users/${comment.user.public_id}`} className="comment__user" style={{ backgroundImage: `url(${comment.user.icon})` }}></a>
        <div className="comment__body">
          <div className="flex-wrap items-center">
            <h2 className="comment__name">{comment.user.name}</h2>
            <p className="comment__date">{comment.display_datetime}</p>
          </div>
          <p className="comment__content" dangerouslySetInnerHTML={{ __html: this.setMessage(comment.comment) }}></p>
          {
            comment.user.public_id == this.props.pid ? <div className="action-links">
              <a href={`/forum_comments/edit?forum=${this.props.forumId}${this.props.eventId ? `&event=${this.props.eventId}` : ''}${this.props.communityId ? `&community=${this.props.communityId}` : ''}&comment_id=${comment.id}`} className="action-link">編集</a>
              <a href={`/forum_comments?forum=${this.props.forumId}${this.props.eventId ? `&event=${this.props.eventId}` : ''}${this.props.communityId ? `&community=${this.props.communityId}` : ''}&comment_id=${comment.id}`} data-confirm="削除してもよろしいですか？" className="action-link" data-method="delete" rel="nofollow">削除</a>
            </div> : ''
          }
        </div>
      </div>);
    }

    return <div className="comments">
      {comments}
    </div>;
  }
}

forumComments.propTypes = {
  baseURI: PropTypes.string,
  forumId: PropTypes.string,
  eventId: PropTypes.string,
  communityId: PropTypes.string,
  pid: PropTypes.string,
};
export default forumComments
