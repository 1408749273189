import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

class threadChildReplies extends React.Component {
  state = {
    isLoading: true,
    replies: [],
    page: 1,
    isAllRead: true,
  };

  fetchChildReplies(page, direction) {
    this.setState({
      isLoading: true,
    });
    axios({
      method: "GET",
      url: `${this.props.baseURI}api/v1/thread_replies/fetch_child_replies`,
      params: {
        page: page,
        pid: this.props.thread.public_id,
        parent: this.props.parentReplyId,
      },
    }).then((response) => {
      const message = response.data.message;
      if (!message) {
        this.organizeThreads(response.data.replies, direction);
        this.setState({
          isLoading: false,
        });
      } else {
        console.log(message);
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  organizeThreads(threads, direction) {
    const currentReplies = this.state.replies;
    const fetchReplies = threads;
    let concatReplies = [];
    if (direction === "head") {
      concatReplies = fetchReplies.concat(currentReplies);
    } else if (direction === "bottom") {
      concatReplies = currentReplies.concat(fetchReplies);
    }

    // 重複を削除
    let replyIds = concatReplies.map((reply) => reply.public_id);
    replyIds = [...new Set(replyIds)];
    let updateReplies = concatReplies.map((reply) => {
      if (replyIds.includes(reply.public_id)) {
        const newreplyIds = replyIds.filter((t) => t !== reply.public_id);
        replyIds = newreplyIds;
        return reply;
      }
    });
    updateReplies = updateReplies.filter(Boolean);

    // まだ取得できるかを判別
    let isAllRead = false;
    if (fetchReplies.length < 10) {
      isAllRead = true;
    }
    this.setState({
      replies: updateReplies,
      isAllRead: isAllRead,
      isLoading: false,
    });
  }

  moreReadingReplies() {
    this.fetchChildReplies(this.state.page + 1, "bottom");
    this.setState({
      page: this.state.page + 1,
    });
  }

  setMessage(text) {
    // 改行タグを<br />に置き換える
    let returnText = this.stripTags(text, "<br>").replace(/\r?\n/g, "<br />");

    // リンクが含まれたテキストにaタグを追加する
    return (returnText = this.autoLink(returnText));
  }

  stripTags(text, allowed) {
    allowed = (
      ((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
    ).join("");
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    return text.replace(tags, ($0, $1) =>
      allowed.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : ""
    );
  }

  autoLink(text) {
    const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    const regexp_makeLink = (all, url, h, href) => {
      if (href.toLowerCase().indexOf("javascript:") > 0) return url;
      return `<a href="h${href}" class="link-color under-line inner-link" target="_blank" rel="nofollow noopener noreferrer">${url}</a>`;
    };

    return text.replace(regexp_url, regexp_makeLink);
  }

  transitionPage(e) {
    e.stopPropagation();
    let uri;

    // 移動関数
    const transition = () => {
      if (uri) {
        window.location.href = uri;
      }
    };

    // テキスト内のリンククリックを判別
    const innerLinks = e.currentTarget.querySelectorAll(".inner-link");
    if (innerLinks.length > 0) {
      innerLinks.forEach((link) => {
        link.addEventListener("click", () => {
          uri = link.href;
          return transition();
        });
      });
    }

    // URLが空の場合
    const target = e.currentTarget;
    const action = target.dataset.action;
    uri = target.dataset.uri;

    if (action) {
      if (confirm("スレッドを削除してもよろしいですか？")) {
        transition();
      } else {
        return;
      }
    } else {
      transition();
    }
  }

  checkLoadingStatus() {
    let status = document.querySelector(
      `#replySuggest-${this.props.thread.public_id}`
    );

    if (status.dataset.loading === "true") {
      this.fetchChildReplies(1, "head");
      status.dataset.loading = "false";
    }
  }

  componentDidMount() {
    this.fetchChildReplies(1, "head");

    // 1秒に1回チェック
    this.timerID = setInterval(() => {
      this.checkLoadingStatus();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    let replies = <p>まだ投稿がありません</p>;

    if (this.state.replies) {
      replies = this.state.replies.map((reply, key) => (
        <div className="reply child" key={key}>
          <div
            className="icon"
            style={{ backgroundImage: `url(${reply.user.icon})` }}
            data-uri={`/users/${reply.user.public_id}`}
            onClick={this.transitionPage.bind(this)}
          ></div>
          <div className="body">
            <div className="meta">
              <p
                className="user-name"
                data-uri={`/users/${reply.user.public_id}`}
                onClick={this.transitionPage.bind(this)}
              >
                {reply.user.name}
              </p>
              <p className="date">{reply.post_date_disp}</p>
              {this.props.userPid === reply.user.public_id ||
              this.props.isAdmin ? (
                <a
                  className="delete"
                  data-action="delete"
                  data-uri={`/thread_replies/${reply.public_id}/delete`}
                  onClick={this.transitionPage.bind(this)}
                >
                  <i className="fas fa-trash"></i>
                </a>
              ) : (
                ""
              )}
            </div>
            <p
              className="content"
              dangerouslySetInnerHTML={{
                __html: this.setMessage(reply.content),
              }}
            ></p>
            <div className={`thread-image ${reply.image_uri ? "show" : ""}`}>
              <img
                src={reply.image_uri}
                alt={`${reply.user.name}さんの投稿画像`}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      ));
    }

    const moreButton = (
      <button className="more" onClick={this.moreReadingReplies.bind(this)}>
        もっと読み込む
      </button>
    );

    return (
      <div className="threads replies child">
        {replies}
        {this.state.isLoading ? (
          <span className="loading">
            <i className="fas fa-spinner fa-spin"></i>
          </span>
        ) : (
          ""
        )}
        {this.state.isAllRead ? "" : moreButton}
      </div>
    );
  }
}

threadChildReplies.propTypes = {
  baseURI: PropTypes.string,
  userPid: PropTypes.string,
  parentReplyId: PropTypes.string,
  isAdmin: PropTypes.bool,
  isOpen: PropTypes.bool,
  thread: PropTypes.object,
};
export default threadChildReplies;
