import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class forumComments extends React.Component {
  state = {
    isFollow: this.props.isFollow,
  };

  follow() {
    let action = 'POST'
    if (this.state.isFollow) {
      action = 'DELETE'
    }

    axios({
      method: action,
      url: `${this.props.baseURI}api/v1/users/${this.props.targetUserPid}/follows`,
      params: {
        uid: this.props.uid,
      }
    }).then((response) => {
      this.setState({
        isFollow: response.data.is_follow
      }, () => {
        const followStatus = document.querySelector('#followStatus');
        followStatus.dataset.loading = 'true';
      })
    });
  }

  render() {
    return <button
      className={`button ${this.state.isFollow ? 'following' : 'follow'}`}
      onClick={this.follow.bind(this)}
    >
      <span className="txt">{this.state.isFollow ? 'フォロー中' : 'フォローする'}</span>
    </button>;
  }
}

forumComments.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  targetUserPid: PropTypes.string,
  isFollow: PropTypes.bool,
};
export default forumComments
