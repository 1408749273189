import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import CategoryItems from "./results"

class marketsCategoriesSelector extends React.Component {
  state = {
    grandparentCategories: [],
    parentCategories: [],
    categories: [],
    currentCategory: this.props.currentCategory,
    sort: false,
  };

  async fetchGrandparentCategories() {
    axios({
      method: "GET",
      url: `${this.props.baseURI}api/v1/sale_item_categories/first_categories`,
    }).then((response) => {
      const categories = response.data;
      this.setState({
        grandparentCategories: categories,
      });
    });
  }

  async fetchParentCategories(publicId, setValue) {
    axios({
      method: "GET",
      url: `${this.props.baseURI}api/v1/sale_item_categories/${publicId}/fetch_categories_key_parent`,
    }).then((response) => {
      const categories = response.data;
      this.setState(({
        parentCategories: categories,
      }), () => {
        if (!setValue) return;
        const parentCategoryDom = document.querySelector('#parentCategory');
        const parentOption = parentCategoryDom.querySelector(`#${setValue}`);
        parentOption.selected = true
      });
    });
  }

  async fetchCategories(publicId, setValue) {
    axios({
      method: "GET",
      url: `${this.props.baseURI}api/v1/sale_item_categories/${publicId}/fetch_categories_key_parent`,
    }).then((response) => {
      const categories = response.data;
      this.setState(({
        categories: categories,
      }), () => {
        if (!setValue) return;
        const categoryDom = document.querySelector('#category');
        const option = categoryDom.querySelector(`#${setValue}`);
        option.selected = true
      });
    });
  }

  // DOMに初期値を挿入する
  async selectCurrentDom(grandparentCategory, parentCategory, category) {
    const grandparentCategoryDom = document.querySelector('#grandparentCategory');
    const grandparentOption = grandparentCategoryDom.querySelector(`#${grandparentCategory}`);

    if (grandparentOption) {
      grandparentOption.selected = true
      if (parentCategory) await this.fetchParentCategories(grandparentCategory, parentCategory);
      if (category) await this.fetchCategories(parentCategory, category);

      // 親カテゴリの場合、子カテゴリを取得
      if (grandparentCategory && !parentCategory) await this.fetchParentCategories(grandparentCategory, parentCategory);
      if (parentCategory && !category) await this.fetchCategories(parentCategory, category);
    }
  }

  currentPosition(publicId) {
    if (!publicId) return;
    axios({
      method: "GET",
      url: `${this.props.baseURI}api/v1/sale_item_categories/${publicId}/fetch_current_position`,
    }).then((response) => {
      const grandparentCategory = response.data.grandparent.public_id;
      const parentCategory = response.data.parent ? response.data.parent.public_id : null;
      const category = response.data.category ? response.data.category.public_id : null;
      this.selectCurrentDom(grandparentCategory, parentCategory, category)
    });
  }


  changeGrandparentCategory(e) {
    const publicId = e.currentTarget.value;
    this.setState(({
      parentCategories: [],
      categories: [],
      currentCategory: publicId,
    }), () => {
      if (publicId) {
        this.fetchParentCategories(publicId);
      }
    });
  }

  changeParentCategory(e) {
    const publicId = e.currentTarget.value;
    this.setState(({
      categories: [],
      currentCategory: publicId,
    }), () => {
      if (publicId) {
        this.fetchCategories(publicId);
      }
    });
  }

  changeCategory(e) {
    const publicId = e.currentTarget.value;
    this.setState({
      currentCategory: publicId,
    });
  }

  changeSortType(e) {
    const sort = e.currentTarget.checked;
    this.setState({
      sort: sort,
    });
  }

  componentDidMount() {
    this.fetchGrandparentCategories();
    this.currentPosition(this.props.currentCategory);
  }


  render() {

    // Option
    const grandparentCategoryOptions = this.state.grandparentCategories.map((category, key) => (<option key={key} value={category.public_id} id={category.public_id}>{category.name}</option>));

    const parentCategoryOptions = this.state.parentCategories.map((category, key) => (<option key={key} value={category.public_id} id={category.public_id}>{category.name}</option>));

    const categoryOptions = this.state.categories.map((category, key) => (<option key={key} value={category.public_id} id={category.public_id}>{category.name}</option>));

    return <div className="search-area">
      <div className="categories-form">
        <select className="form-select" id="grandparentCategory" onChange={this.changeGrandparentCategory.bind(this)}>
          <option value="">選択してください</option>
          {grandparentCategoryOptions}
        </select>


        { this.state.parentCategories.length > 0 ?
          <select className="form-select" id="parentCategory" onChange={this.changeParentCategory.bind(this)}>
            <option value="">選択してください</option>
            {parentCategoryOptions}
          </select> : ''
        }

        {this.state.categories.length > 0 ?
          <select className="form-select" id="category" onChange={this.changeCategory.bind(this)}>
            <option value="">選択してください</option>
            {categoryOptions}
          </select> : ''
        }
      </div>


      <label className="sort-label"><input type="checkbox" onChange={this.changeSortType.bind(this)} aria-label="販売中の商品のみ表示する" />&nbsp;販売中の商品のみ表示する</label>

      <CategoryItems baseURI={this.props.baseURI} currentCategory={this.state.currentCategory} sortParam={this.state.sort ? 'selling' : ''} />
    </div>;
  }
}

marketsCategoriesSelector.propTypes = {
  baseURI: PropTypes.string,
  currentCategory: PropTypes.string,
};
export default marketsCategoriesSelector;
