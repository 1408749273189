import React from "react"
import PropTypes from "prop-types"
import ChatButton from "./button"
import ChatContainer from "./container"


class chatBase extends React.Component {

  render() {
    return <React.Fragment>
      <ChatButton />
      <ChatContainer
        baseURI={this.props.baseURI}
        uid={this.props.uid}
      />
    </React.Fragment>;
  }
}

chatBase.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
};
export default chatBase
