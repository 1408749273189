import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

class threadIndex extends React.Component {
  state = {
    isLoading: true,
    threads: [],
    page: 1,
    isAllRead: true,
  };

  fetchThreads(page, direction) {
    this.setState({
      isLoading: true,
    });
    axios({
      method: "GET",
      url: `${this.props.baseURI}api/v1/threads/fetch_threads`,
      params: {
        page: page,
      },
    }).then((response) => {
      const message = response.data.message;
      if (!message) {
        this.organizeThreads(response.data.threads, direction);
        this.setState({
          isLoading: false,
        });
      } else {
        console.log(message);
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  organizeThreads(threads, direction) {
    const currentThreads = this.state.threads;
    const fetchThreads = threads;
    let concatThreads = [];
    if (direction === "head") {
      concatThreads = fetchThreads.concat(currentThreads);
    } else if (direction === "bottom") {
      concatThreads = currentThreads.concat(fetchThreads);
    }

    // 重複を削除
    let threadIds = concatThreads.map((thread) => thread.public_id);
    threadIds = [...new Set(threadIds)];
    let updateThreads = concatThreads.map((thread) => {
      if (threadIds.includes(thread.public_id)) {
        const newThreadIds = threadIds.filter((t) => t !== thread.public_id);
        threadIds = newThreadIds;
        return thread;
      }
    });
    updateThreads = updateThreads.filter(Boolean);

    // まだ取得できるかを判別
    let isAllRead = false;
    if (fetchThreads.length < 10) {
      isAllRead = true;
    }
    this.setState({
      threads: updateThreads,
      isAllRead: isAllRead,
      isLoading: false,
    });
  }

  moreReadingThreads() {
    this.fetchThreads(this.state.page + 1, "bottom");
    this.setState({
      page: this.state.page + 1,
    });
  }

  setMessage(text) {
    // 改行タグを<br />に置き換える
    let returnText = this.stripTags(text, "<br>").replace(/\r?\n/g, "<br />");

    // リンクが含まれたテキストにaタグを追加する
    return (returnText = this.autoLink(returnText));
  }

  stripTags(text, allowed) {
    allowed = (
      ((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
    ).join("");
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    return text.replace(tags, ($0, $1) =>
      allowed.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : ""
    );
  }

  autoLink(text) {
    const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    const regexp_makeLink = (all, url, h, href) => {
      if (href.toLowerCase().indexOf("javascript:") > 0) return url;
      return `<span data-uri="h${href}" class="link-color under-line inner-link" rel="nofollow noopener noreferrer">${url}</span>`;
    };

    return text.replace(regexp_url, regexp_makeLink);
  }

  transitionPage(e) {
    e.stopPropagation();
    let uri;

    // 移動関数
    const transition = () => {
      if (uri) {
        window.location.href = uri;
      }
    };

    const openWindow = () => {
      if (uri) {
        window.open(uri, "_blank");
      }
    };

    // テキスト内のリンククリックを判別
    const innerLinks = e.currentTarget.querySelectorAll(".inner-link");
    if (innerLinks.length > 0) {
      innerLinks.forEach((link) => {
        link.addEventListener("click", () => {
          uri = link.dataset.uri;
          return openWindow();
        });
      });
    }

    // URLが空の場合
    const target = e.currentTarget;
    const action = target.dataset.action;
    uri = target.dataset.uri;

    if (action) {
      if (confirm("スレッドを削除してもよろしいですか？")) {
        transition();
      } else {
        return;
      }
    } else {
      transition();
    }
  }

  checkLoadingStatus() {
    const status = document.getElementById("status");
    if (status && status.dataset.loading === "true") {
      status.dataset.loading = "false";
      this.fetchThreads(1, "head");
    }
  }

  componentDidMount() {
    this.fetchThreads(1, "head");

    // 1秒に1回チェック
    this.timerID = setInterval(() => {
      this.checkLoadingStatus();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    clearInterval(this.reloadID);
  }

  render() {
    let threads = <p>まだ投稿がありません</p>;

    if (this.state.threads) {
      threads = this.state.threads.map((thread, key) => (
        <div
          className="thread"
          key={key}
          data-uri={`/threads/${thread.public_id}`}
          onClick={this.transitionPage.bind(this)}
        >
          <div
            className="icon"
            style={{ backgroundImage: `url(${thread.user.icon})` }}
            data-uri={`/users/${thread.user.public_id}`}
            onClick={this.transitionPage.bind(this)}
          ></div>
          <div className="body">
            <div className="meta">
              <p
                className="user-name"
                data-uri={`/users/${thread.user.public_id}`}
                onClick={this.transitionPage.bind(this)}
              >
                {thread.user.name}
              </p>
              <p className="date">{thread.post_date_disp}</p>
              {this.props.userPid === thread.user.public_id ||
              this.props.isAdmin ? (
                <a
                  className="delete"
                  data-action="delete"
                  data-uri={`/threads/${thread.public_id}/delete`}
                  onClick={this.transitionPage.bind(this)}
                >
                  <i className="fas fa-trash" aria-hidden></i>
                </a>
              ) : (
                ""
              )}
            </div>
            <p
              className="content"
              dangerouslySetInnerHTML={{
                __html: this.setMessage(thread.content),
              }}
            ></p>
            <div
              className={`thread-image ${thread.image_uri ? "show" : ""}`}
              style={{ backgroundImage: `url(${thread.image_uri})` }}
            ></div>
          </div>
        </div>
      ));
    }

    const moreButton = (
      <button className="more" onClick={this.moreReadingThreads.bind(this)}>
        もっと読み込む
      </button>
    );

    return (
      <div className="threads">
        {threads}
        {this.state.isLoading ? (
          <span className="loading">
            <i className="fas fa-spinner fa-spin" aria-hidden></i>
          </span>
        ) : (
          ""
        )}
        {this.state.isAllRead ? (
          <p className="fin-message">これ以上過去の投稿が見つかりません</p>
        ) : (
          moreButton
        )}
      </div>
    );
  }
}

threadIndex.propTypes = {
  baseURI: PropTypes.string,
  userPid: PropTypes.string,
  isAdmin: PropTypes.bool,
};
export default threadIndex;
