import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class chatBodyIndex extends React.Component {
  state = {
    rooms: [],
    isLoading: true,
  };

  changeStatus(e) {
    const roomId = e.currentTarget.dataset.room;
    this.props.changeRoomId(roomId);
    this.props.changePageType('show');
  }

  fetchDmRooms() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/dm_rooms`,
      params: {
        uid: this.props.uid,
      }
    }).then((response) => {
      this.setState({
        rooms: response.data.dm_rooms,
        isLoading: false
      });
    });
  };

  loadingCheck() {
    const chatStatus = document.getElementById('chatStatus');
    if (chatStatus.dataset.loading == 'true') {
      chatStatus.dataset.loading = 'false'
      this.fetchDmRooms();
    }
  }

  componentDidMount() {
    this.fetchDmRooms();
    this.loadingCheck();

    // 1秒に1回チェック
    this.timerID = setInterval(() => {
      this.loadingCheck();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    let rooms = []
    if (this.state.rooms.length > 0) {
      rooms = this.state.rooms.map((room, key) => <div className="room" onClick={this.changeStatus.bind(this)} key={key} data-room={room.public_id}>
        <div className="room__image" style={{ backgroundImage: `url(${room.image})` }}></div>
        <div className="room__content">
          <div className="flex items-center">
            <div className="room__ttl">{room.name}</div>
            <div className="room__users">{room.users_count ? `(${room.users_count})` : ''}</div>
          </div>
          <div className="room__detail">{room.last_content}</div>
        </div>
        <div className="ml-auto">
          <div className="room__date">{room.display_datetime}</div>
          {room.count > 0 ? <div className="room__count">{room.count}</div> : ''}
        </div>
      </div>);
    } else {
      rooms = <p className="not-room">{this.state.isLoading ? 'メッセージを読み込んでいます...' : 'メッセージがありません。'}</p>;
    }

    return <div className="rooms">
      {rooms}
    </div>;
  }
}

chatBodyIndex.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  changePageType: PropTypes.func,
  changeRoomId: PropTypes.func,
};
export default chatBodyIndex
