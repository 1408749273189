import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class ContentSupportBar extends React.Component {
  state = {
    slug: this.props.mediaSlug === null ? 'mediaimage' : this.props.mediaSlug,
    selectStart: 0,
    selectEnd: 0,
    selectValue: '',
    isImageModaiOpen: false,
    selectedImageUri: '',
    mediaImages: [],
    isUploading: false,
  };

  insertH2() {
    this.insertFunc('<h2>', '</h2>');
    this.setState({
      selectStart: 0,
      selectEnd: 0,
      selectValue: '',
    });
  }

  insertH3() {
    this.insertFunc('<h3>', '</h3>');
    this.setState({
      selectStart: 0,
      selectEnd: 0,
      selectValue: '',
    });
  }

  insertH4() {
    this.insertFunc('<h4>', '</h4>');
    this.setState({
      selectStart: 0,
      selectEnd: 0,
      selectValue: '',
    });
  }

  insertUl() {
    this.insertFunc('<ul>', '</ul>');
    this.setState({
      selectStart: 0,
      selectEnd: 0,
      selectValue: '',
    });
  }

  insertOl() {
    this.insertFunc('<ol>', '</ol>');
    this.setState({
      selectStart: 0,
      selectEnd: 0,
      selectValue: '',
    });
  }

  insertLi() {
    this.insertFunc('<li>', '</li>');
    this.setState({
      selectStart: 0,
      selectEnd: 0,
      selectValue: '',
    });
  }

  insertP() {
    this.insertFunc('<p>', '</p>');
    this.setState({
      selectStart: 0,
      selectEnd: 0,
      selectValue: '',
    });
  }

  insertFunc(prefix, suffix) {
    if (this.state.selectStart === this.state.selectEnd) {
      alert('タグの挿入範囲が選択されていません');
      return;
    }

    const textarea = document.querySelector('#content');
    let val = textarea.value;
    let range = val.slice(this.state.selectStart, this.state.selectEnd);
    let beforeNode = val.slice(0, this.state.selectStart);
    let afterNode = val.slice(this.state.selectEnd);
    let insertNode = prefix + range + suffix;
    textarea.value = beforeNode + insertNode + afterNode;
  }

  insertImageTag(imageUri) {
    if (imageUri === '') {
      alert('挿入する画像を選択してください');
      return;
    }
    const textarea = document.querySelector('#content');
    let val = textarea.value;
    let range = val.slice(this.state.selectStart, this.state.selectEnd);
    let beforeNode = val.slice(0, this.state.selectStart);
    let afterNode = val.slice(this.state.selectEnd);
    let insertNode = range + `<img src="${imageUri}" alt="" />`;
    textarea.value = beforeNode + insertNode + afterNode;

    this.closeImageModal();
  }

  checkSelectRange() {
    const content = document.querySelector('#content');
    content.addEventListener('mouseup', () => selectRange());
    content.addEventListener('keyup', () => selectRange());

    const selectRange = () => {
      const start = content.selectionStart;
      const end = content.selectionEnd;

      this.setState({
        selectStart: start,
        selectEnd: end,
      });
    }
  }

  openImageModal() {
    this.setState({
      isImageModaiOpen: true,
    });
    document.body.style.overflow = 'hidden';
  }

  closeImageModal() {
    this.setState({
      isImageModaiOpen: false,
      selectedImageUri: '',
    });
    document.body.style.overflow = 'auto';
  }

  uploadImage(e) {
    this.setState({
      isUploading: true
    })

    const createFormData = () => {
      const file_list = e.currentTarget.files;
      if (!file_list) return;
      const file = file_list[0];
      if (!file) return;

      const formData = new FormData()
      formData.append('post[image]', file)
      formData.append('post[slug]', this.state.slug)
      return formData
    }

    const sendForm = async () => {
      const url = `${this.props.baseURI}/api/v1/cloudinary/upload_image`;
      const data = await createFormData();
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      axios.post(url, data, config).then(response => {
        this.setState({
          isUploading: false
        })
        this.fetchAllImage()

      }).catch(error => {
        console.log(error)
      });
    }
    sendForm();
  }

  fetchAllImage() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/cloudinary/fetch_all_image`,
      params: {
        slug: this.state.slug,
      }
    }).then((response) => {
      this.setState({
        mediaImages: response.data.images
      });
    });
  }

  setPreviewImage(image) {
    this.setState({
      selectedImageUri: image
    });
  }

  componentDidMount() {
    this.checkSelectRange()
    this.fetchAllImage()
  }

  render() {
    const mediaImages = this.state.mediaImages.map(
      (image, key) => <div
        className="image-card"
        style={{ backgroundImage: `url(${image})` }}
        key={key}
        onClick={this.setPreviewImage.bind(this, image)}
      ></div>
    );

    return <div className="content-support-bar">
      <div className="icon image" onClick={this.openImageModal.bind(this)}><i className="fas fa-image fa-fw"></i></div>
      <div className="icon text" onClick={this.insertH2.bind(this)}>h2</div>
      <div className="icon text" onClick={this.insertH3.bind(this)}>h3</div>
      <div className="icon text" onClick={this.insertH4.bind(this)}>h4</div>
      <div className="icon text" onClick={this.insertUl.bind(this)}>ul</div>
      <div className="icon text" onClick={this.insertOl.bind(this)}>ol</div>
      <div className="icon text" onClick={this.insertLi.bind(this)}>li</div>
      <div className="icon text" onClick={this.insertP.bind(this)}>p</div>
      <div className={`image-modal ${this.state.isImageModaiOpen ? 'open' : ''}`}>
        <div className="overlay" onClick={this.closeImageModal.bind(this)}></div>
        <div className="content">
          <div className="head">
            <span className="left"><i className="fas fa-angle-left fa-fw"></i></span>
            <span className="center">画像一覧</span>
            <span className="right" onClick={this.closeImageModal.bind(this)}><i className="fas fa-times fa-fw"></i></span>
          </div>
          <div className="body">
            <div className="images">
              <label className={`image-card plus ${this.state.isUploading ? 'disabled' : ''}`}>
                <i className={`fas fa-fw ${this.state.isUploading ? 'fa-spinner fa-spin' : 'fa-plus'}`}></i>
                <input type="file" accept="image/png, image/jpeg, image/gif, image/webp" className="input" onChange={this.uploadImage.bind(this)} disabled={this.state.isUploading ? true : false} />
              </label>
              {mediaImages}
            </div>
            <div className="preview-image" style={{ backgroundImage: `url(${this.state.selectedImageUri})` }}>
              <div className="insert-button" onClick={this.insertImageTag.bind(this, this.state.selectedImageUri)}>画像を挿入する</div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

ContentSupportBar.propTypes = {
  baseURI: PropTypes.string,
  mediaSlug: PropTypes.string
};
export default ContentSupportBar
