import React from "react"
import PropTypes from "prop-types"
import ChatHead from "./head/base"
import ChatBody from "./body/base"

class chatBox extends React.Component {
  state = {
    pageType: 'index',
    roomId: ''
  };

  changePageType(pageType) {
    this.setState({
      pageType: pageType
    });
  }

  changeRoomId(roomId) {
    this.setState({
      roomId: roomId
    });
  }

  checkOpenRoom() {
    const STATUS = document.getElementById('chatStatus');
    const roomId = STATUS.dataset.room
    if (roomId) {
      STATUS.dataset.room = '';
      this.setState({
        pageType: 'show',
        roomId: roomId,
      });
    }
  }

  componentDidMount() {
    // 1秒に1回チェック
    this.timerID = setInterval(() => {
      this.checkOpenRoom();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return <div className="chat-box">
      <ChatHead
        baseURI={this.props.baseURI}
        pageType={this.state.pageType}
        changePageType={(pageType) => { this.changePageType(pageType); }}
        roomId={this.state.roomId}
      />
      <ChatBody
        baseURI={this.props.baseURI}
        uid={this.props.uid}
        pageType={this.state.pageType}
        changePageType={(pageType) => { this.changePageType(pageType); }}
        changeRoomId={(roomId) => { this.changeRoomId(roomId); }}
        roomId={this.state.roomId}
      />
    </div>;
  }
}

chatBox.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
};
export default chatBox
