import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

class marketsCategoriesResults extends React.Component {
  state = {
    items: [],
  };

  fetchItems() {
    axios({
      method: "GET",
      url: `${this.props.baseURI}api/v1/sale_item_categories/fetch_items`,
      params: {
        public_id: this.props.currentCategory ? this.props.currentCategory : '',
        sort: this.props.sortParam,
      },
    }).then((response) => {
      const items = response.data;
      this.setState({
        items: items,
      });
    });
  }

  setSoldDOM(item) {
    return item.is_buyer ? <div className="item-selling-status red">
      <p className="txt bold">SOLD</p>
    </div> : '';
  }

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.currentCategory === this.props.currentCategory && nextProps.sortParam === this.props.sortParam) return;

    this.fetchItems();
  }


  render() {
    const items = this.state.items.map((item, key) => (<a key={key} className="item" href={`/markets/items/${item.public_id}`}>
      <div className="image">
        <img src={item.thumb} alt={item.name} loading="lazy " />
        <p className="price">¥{ item.price_disp}</p>
        {this.setSoldDOM(item)}
      </div>
      <h3 className="item-ttl">{item.name}</h3>
    </a>));

    return <div className="sale-items">
      <div className="items">{items}</div>
    </div>;
  }
}

marketsCategoriesResults.propTypes = {
  baseURI: PropTypes.string,
  currentCategory: PropTypes.string,
  sortParam: PropTypes.string,
};
export default marketsCategoriesResults;
