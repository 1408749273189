import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class chatHeadShow extends React.Component {
  state = {
    roomName: '',
    count: '',
  };

  changePage() {
    this.props.changePageType('index');
  }

  fetchRoomName() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/dm_rooms/fetch_room_name`,
      params: {
        dm_room: this.props.roomId,
      }
    }).then((response) => {
      this.setState({
        roomName: response.data.name,
        count: response.data.count,
      });
    });
  };

  componentDidMount() {
    this.fetchRoomName();
  }

  render() {
    return <React.Fragment>
      <div className="left-content">
        <p className="icon" onClick={this.changePage.bind(this)}><i className="fas fa-angle-left"></i></p>
        <p className="title">{this.state.roomName}</p>
        <p className="title">{this.state.count ? `(${this.state.count})` : ''}</p>
      </div>
      <div className="center-content">
      </div>
      <div className="right-content">
        <div id="closeModal" className="close-button" onClick={closeModal.bind(this)}><i className="fas fa-times"></i></div>
      </div>
    </React.Fragment>;
  }
}

chatHeadShow.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  changePageType: PropTypes.func,
  roomId: PropTypes.string,
};
export default chatHeadShow
