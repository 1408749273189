import React from "react";
import PropTypes from "prop-types";

class threadShow extends React.Component {
  state = {
    content: this.setMessage(this.props.thread.content),
  };

  setMessage(text) {
    // 改行タグを<br />に置き換える
    let returnText = this.stripTags(text, "<br>").replace(/\r?\n/g, "<br />");

    // リンクが含まれたテキストにaタグを追加する
    return (returnText = this.autoLink(returnText));
  }

  stripTags(text, allowed) {
    allowed = (
      ((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
    ).join("");
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    return text.replace(tags, ($0, $1) =>
      allowed.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : ""
    );
  }

  autoLink(text) {
    const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    const regexp_makeLink = (all, url, h, href) => {
      if (href.toLowerCase().indexOf("javascript:") > 0) return url;
      return `<a href="h${href}" class="link-color under-line inner-link" target="_blank" rel="nofollow noopener noreferrer">${url}</a>`;
    };

    return text.replace(regexp_url, regexp_makeLink);
  }

  transitionPage(e) {
    e.stopPropagation();
    let uri;

    // 移動関数
    const transition = () => {
      if (uri) {
        window.location.href = uri;
      }
    };

    const openWindow = () => {
      if (uri) {
        window.open(uri, "_blank");
      }
    };

    // テキスト内のリンククリックを判別
    const innerLinks = e.currentTarget.querySelectorAll(".inner-link");
    if (innerLinks.length > 0) {
      innerLinks.forEach((link) => {
        link.addEventListener("click", () => {
          uri = link.dataset.uri;
          return openWindow();
        });
      });
    }

    // URLが空の場合
    const target = e.currentTarget;
    const action = target.dataset.action;
    uri = target.dataset.uri;

    if (action) {
      if (confirm("スレッドを削除してもよろしいですか？")) {
        transition();
      } else {
        return;
      }
    } else {
      transition();
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="main-thread">
        <div className="head">
          <a
            className="icon"
            style={{ backgroundImage: `url(${this.props.thread.user.icon})` }}
            href={`/users/${this.props.thread.user.public_id}`}
          ></a>
          <div className="body">
            <div className="meta">
              <a
                className="user-name"
                href={`/users/${this.props.thread.user.public_id}`}
              >
                {this.props.thread.user.name}
              </a>
              <div className="user-sns">
                {this.props.thread.user.sns.map((snsService, key) => <a
                  key={key}
                  href={`${snsService.sns_service.before_url}${snsService.content}`}
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  aria-label={`${snsService.sns_service.name}のボタン`}
                  dangerouslySetInnerHTML={{
                    __html: snsService.sns_service.icon,
                  }}
                  >
                </a>)}
              </div>
              <p className="date">{this.props.thread.post_date_disp}</p>
              {this.props.userPid === this.props.thread.user.public_id ||
              this.props.isAdmin ? (
                <a
                  className="delete"
                  href={`/threads/${this.props.thread.public_id}/delete`}
                  data-action="delete"
                  onClick={this.transitionPage.bind(this)}
                >
                  <i className="fas fa-trash" aria-hidden></i>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <p
          className="content"
          dangerouslySetInnerHTML={{
            __html: this.setMessage(this.state.content),
          }}
        ></p>
        <div
          className={`thread-image ${
            this.props.thread.image_uri ? "show" : ""
          }`}
        >
          <img
            src={this.props.thread.image_uri}
            alt={`${this.props.thread.user.name}さんの投稿画像`}
          />
        </div>
      </div>
    );
  }
}

threadShow.propTypes = {
  baseURI: PropTypes.string,
  userPid: PropTypes.string,
  thread: PropTypes.object,
  isAdmin: PropTypes.bool,
};
export default threadShow;
