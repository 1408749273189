import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import Items from "./results"

class searchSaleItems extends React.Component {
  state = {
    keyword: this.props.keyword,
    items: [],
    sort: false,
  };

  fetchItems() {
    axios({
      method: "GET",
      url: `${this.props.baseURI}api/v1/sale_item_searches/fetch_items`,
      params: {
        keyword: this.state.keyword,
        sort: this.state.sort ? 'selling' : '',
      },
    }).then((response) => {
      const items = response.data;
      this.setState({
        items: items,
      });
    });
  }

  changeKeyword(e) {
    const keyword = e.currentTarget.value;
    this.setState({
      keyword: keyword,
    });
  }

  changeSortType(e) {
    const sort = e.currentTarget.checked;
    this.setState(({
      sort: sort,
    }), () => {
      this.fetchItems();
    });
  }

  componentDidMount() {
    this.fetchItems();
  }

  render() {

    return <div className="search-area">
      <div className="search-form">
        <input type="text" className="input" onChange={this.changeKeyword.bind(this)} defaultValue={this.props.keyword} />
        <button className="btn" onClick={this.fetchItems.bind(this)}>探す</button>
      </div>


      <label className="sort-label"><input type="checkbox" onChange={this.changeSortType.bind(this)} aria-label="販売中の商品のみ表示する" />&nbsp;販売中の商品のみ表示する</label>
      <Items baseURI={this.props.baseURI} items={this.state.items} />
    </div>;
  }
}

searchSaleItems.propTypes = {
  baseURI: PropTypes.string,
  keyword: PropTypes.string,
};
export default searchSaleItems;
