import consumer from "./consumer"

consumer.subscriptions.create("ForumChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    const forumData = document.getElementById('forumData');
    if (forumData.dataset.forum == data['forum'] && forumData.dataset.event == data['event']) {
      forumData.dataset.loading = 'true'
    }

    if (forumData.dataset.forum == data['forum'] && forumData.dataset.community == data['community']) {
      forumData.dataset.loading = 'true'
    }
  }
});
