import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class ChatForm extends React.Component {
  state = {
    content: '',
    isActive: true,
  };

  setContent(e) {
    this.setState({ content: this.strip_tags(e.target.value, '') });
  }

  strip_tags(input, allowed) {
    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    return input.replace(tags, ($0, $1) => (allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''))
  }

  submit() {
    this.setState({ isActive: false });
    this.postMessage();
  }

  postMessage() {
    if (this.state.content.length > 0) {
      axios({
        method: 'POST',
        url: `${this.props.baseURI}api/v1/dm_contents`,
        params: {
          uid: this.props.uid,
          dm_room: this.props.roomId,
          content: this.state.content,
        }
      }).then(() => {
        this.props.fetchMessages();
        this.setState({
          content: '',
          isActive: true
        });
      }).catch((error) => {
        console.log(error.response.data);
        this.setState({ isActive: true });
      });
    } else {
      alert('メッセージを入力してください');
      this.setState({ isActive: true });
    }
  }

  render() {
    let sendButton = this.state.isActive ? <div className="send-box__btn" onClick={this.submit.bind(this)}>送信</div> : <div className="send-box__btn false">送信中</div>;

    return (
      <div className="send-box">
        <textarea className="send-box__form" placeholder="メッセージを入力して下さい..." value={this.state.content} onChange={this.setContent.bind(this)}></textarea>
        {sendButton}
      </div>
    );
  }
}

ChatForm.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  fetchMessages: PropTypes.func,
  roomId: PropTypes.string,
};
export default ChatForm
