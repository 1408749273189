import React from "react";
import PropTypes from "prop-types";

class searchSaleItemResults extends React.Component {

  setSoldDOM(item) {
    return item.is_buyer ? <div className="item-selling-status red">
      <p className="txt bold">SOLD</p>
    </div> : '';
  }

  render() {
    const items = this.props.items.map((item, key) => (<a key={key} className="item" href={`/markets/items/${item.public_id}`}>
      <div className="image">
        <img src={item.thumb} alt={item.name} loading="lazy " />
        <p className="price">¥{ item.price_disp}</p>
        {this.setSoldDOM(item)}
      </div>
      <h3 className="item-ttl">{item.name}</h3>
    </a>));

    return <div className="sale-items">
      <div className="items">{items}</div>
    </div>;
  }
}

searchSaleItemResults.propTypes = {
  baseURI: PropTypes.string,
  items: PropTypes.array,
};
export default searchSaleItemResults;
