import consumer from "./consumer"

consumer.subscriptions.create("DmChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const chatStatus = document.getElementById('chatStatus');
    if (chatStatus.dataset.pid == data['pid']) {
      chatStatus.dataset.loading = 'true'
      chatStatus.dataset.count = data['count']
    }
  }
});
