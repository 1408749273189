import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class forumComments extends React.Component {
  state = {
    isFollow: this.props.isFollow,
  };

  message() {
    axios({
      method: 'POST',
      url: `${this.props.baseURI}api/v1/dm_rooms/`,
      params: {
        uid: this.props.uid,
        target_user_pid: this.props.targetUserPid,
      }
    }).then((response) => {
      const chatStatus = document.querySelector('#chatStatus');
      chatStatus.dataset.loading = 'true';
      chatStatus.dataset.room = response.data.room;

      const modalArea = document.querySelector('#modalArea');
      modalArea.classList.add('is-show');
    }).catch(error => {
        console.log(error)
      });
  }

  render() {
    return <button
      className="button message"
      onClick={this.message.bind(this)}
    >
      <span className="txt">メッセージを送る</span>
    </button>;
  }
}

forumComments.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  targetUserPid: PropTypes.string,
};
export default forumComments
