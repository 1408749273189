import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class eventTags extends React.Component {
  state = {
    tags: [],
    selectedTagIds: [],
    targetId: 0,
  };

  fetchTagData() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/tags`,
      params: {
        uid: this.props.uid,
      }
    }).then((response) => {
      this.setState({
        tags: response.data.tags
      });
    });
  }

  fetchSelectedTagIds() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/tags/fetch_selected_tags`,
      params: {
        uid: this.props.uid,
        event: this.props.eventPublicId
      }
    }).then((response) => {
      this.setState({
        selectedTagIds: response.data.selected_tags
      });
    });
  }

  add() {
    if (this.state.targetId > 0) {
      const targetIds = this.state.selectedTagIds.map(id => Number(id));
      if (targetIds.indexOf(Number(this.state.targetId)) >= 0) {
        alert('既に追加されています');
      } else {
        axios({
          method: 'PUT',
          url: `${this.props.baseURI}/api/v1/tags`,
          params: {
            uid: this.props.uid,
            event: this.props.eventPublicId,
            tag_id: this.state.targetId,
            method: 'add',
          }
        }).then((response) => {
          this.fetchSelectedTagIds();
          alert(response.data.message);
        });
      }
    } else {
      alert('タグを選択してください');
    }
  }

  remove(e) {
    const targetId = e.currentTarget.getAttribute('data-id');
    if (confirm('イベントからこのタグを解除しますか？')) {
      axios({
        method: 'PUT',
        url: `${this.props.baseURI}/api/v1/tags`,
        params: {
          uid: this.props.uid,
          event: this.props.eventPublicId,
          tag_id: targetId,
          method: 'remove',
        }
      }).then((response) => {
        this.fetchSelectedTagIds();
        alert(response.data.message);
      });
    }
  }

  change(e) {
    this.setState({ targetId: e.currentTarget.value });
  }

  componentDidMount() {
    this.fetchTagData();
    this.fetchSelectedTagIds();
  }

  render() {
    let tags = this.state.tags.map((tag, key) => <option value={tag.id} key={key}>{tag.name}</option>);

    let selectedTags = this.state.selectedTagIds.map((tagId, key) => <div className="news__card" key={key}>
      <div className="flex items-center pd10">
        <h2>{this.state.tags.find((tag) => { return (tag.id === tagId); }).name}</h2>
        <div className="ml-auto pointer" data-id={tagId} onClick={this.remove.bind(this)}><i className="fas fa-times"></i></div>
      </div>
    </div>);


    return <div className="mt20">
      <div className="flex items-center">
        <select className="selector" onChange={this.change.bind(this)}>
          <option disabled selected>タグを選択してください</option>
          {tags}
        </select>
        <button className="ml10 selectBtn" onClick={this.add.bind(this)}>追加</button>
      </div>

      <div className="news__container mt20">
        <div className="news__wrapper">
          {selectedTags}
        </div>
      </div>
    </div>;
  }
}

eventTags.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  eventPublicId: PropTypes.string,
};
export default eventTags
