import React from "react"
import PropTypes from "prop-types"
import Index from "./index"
import Show from "./show"

class chatHead extends React.Component {
  changePage(pageType) {
    this.props.changePageType(pageType);
  }

  render() {
    let CONTENT = this.props.pageType == 'index' ?
      <Index /> :
      <Show
        baseURI={this.props.baseURI}
        roomId={this.props.roomId}
        changePageType={(pageType) => { this.changePage(pageType); }}
      />;
    return <div className="chat-head">
      {CONTENT}
    </div>;
  }
}

chatHead.propTypes = {
  baseURI: PropTypes.string,
  pageType: PropTypes.string,
  changePageType: PropTypes.func,
  roomId: PropTypes.string,
};
export default chatHead
