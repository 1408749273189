import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class forumComments extends React.Component {
  state = {
  };

  fetchFollow() {
    let action = 'GET'

    axios({
      method: action,
      url: `${this.props.baseURI}api/v1/users/${this.props.pid}/follows`,
    }).then((response) => {
      const follow = response.data.follow;
      const follower = response.data.follower;

      const followCount = document.querySelector('#followCount');
      const followerCount = document.querySelector('#followerCount');

      if (followCount) {
        followCount.innerHTML = follow;
      }

      if (followerCount) {
        followerCount.innerHTML = follower;
      }

    });
  }

  checkLoadingStatus() {
    const followStatus = document.getElementById("followStatus");
    if (followStatus && followStatus.dataset.loading === "true") {
      followStatus.dataset.loading = "false";
      this.fetchFollow();
    }
  }

  componentDidMount() {
  // 1秒に1回チェック
    this.timerID = setInterval(() => {
      this.checkLoadingStatus();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return <div></div>;
  }
}

forumComments.propTypes = {
  baseURI: PropTypes.string,
  pid: PropTypes.string,
};
export default forumComments
