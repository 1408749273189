import React from "react"
import PropTypes from "prop-types"

class selectUsers extends React.Component {
  state = {
    users: this.props.users,
    selectedUserIds: []
  };

  handleTarget(e) {
    const VALUE = Number(e.currentTarget.dataset.user);
    if (this.state.selectedUserIds.indexOf(VALUE) >= 0) {
      this.remove(VALUE);
    } else {
      this.add(VALUE);
    }
  }

  add(VALUE) {
    let selectedUserIds = this.state.selectedUserIds;
    selectedUserIds.push(VALUE);
    this.setState({ selectedUserIds: selectedUserIds });
  }

  remove(VALUE) {
    let selectedUserIds = this.state.selectedUserIds;
    selectedUserIds = selectedUserIds.filter(n => n !== VALUE);
    this.setState({ selectedUserIds: selectedUserIds });
  }

  allUserCheck() {
    const userIds = this.state.users.map(user => user.id)
    this.setState({ selectedUserIds: userIds });
  }

  render() {
    let users = this.state.users.map((user, key) => <label className="user" key={key}>
      <input type="checkbox" data-user={user.id} onChange={this.handleTarget.bind(this)} checked={this.state.selectedUserIds.indexOf(user.id) >= 0} />
      <div className="user__img" style={{ backgroundImage: `url(${user.icon})` }}></div>
      <div className="user__meta">
        <h2 className="user__name">{user.name}</h2>
        <p className="user__email">{user.email}</p>
      </div>
    </label>);

    const btnStyle = {
      background: '#5c7339',
      textAlign: 'center',
      margin: '2rem 0 -12px',
      padding: '4px 16px',
      fontSize: '12px',
      width: 'auto',
      display: 'inline-block',
      color: '#fff',
      borderRadius: '4px',
      cursor: 'pointer',
    }

    return <div className="send_mails__container mt20">
      <div style={btnStyle} onClick={this.allUserCheck.bind(this)}>一括チェック</div>
      <div className="mails-users mt20">
        {users}
      </div>
      <a className="defaultBtn mt20" href={`/admin/send_mails/new?template=${this.props.template}&users=${this.state.selectedUserIds}`}>このユーザーにメールを送信する</a>
    </div>;
  }
}

selectUsers.propTypes = {
  baseURI: PropTypes.string,
  users: PropTypes.array,
  template: PropTypes.string,
};
export default selectUsers
