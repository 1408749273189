import React from "react"
import PropTypes from "prop-types"
import ChatBox from "./container/box"


class chatContainer extends React.Component {
  render() {
    return <section id="modalArea" className="modalArea">
      <div id="VpModalBg" className="modalBg" onClick={closeModal.bind(this)}></div>
      <div className="modalWrapper">
        <div className="modalContents">
          <ChatBox
            baseURI={this.props.baseURI}
            uid={this.props.uid}
          />
        </div>
      </div>
    </section>;
  }
}

chatContainer.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string
};
export default chatContainer
