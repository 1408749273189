import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'
import Form from "./form"

class chatBodyShow extends React.Component {
  state = {
    messages: [],
  };

  fetchMessages() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/dm_contents`,
      params: {
        uid: this.props.uid,
        dm_room: this.props.roomId,
      }
    }).then((response) => {
      this.setState({
        messages: response.data.dm_contents
      });
      this.setNotificationCount();
    });
  };

  setNotificationCount() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/dm_users/fetch_count`,
      params: {
        uid: this.props.uid,
      }
    }).then((response) => {
      const chatStatus = document.getElementById('chatStatus');
      chatStatus.dataset.count = response.data.count
    });
  };

  setMessage(text) {
    // 改行タグを<br />に置き換える
    let returnText = this.stripTags(text, '<br>').replace(/\r?\n/g, '<br />')

    // リンクが含まれたテキストにaタグを追加する
    return returnText = this.autoLink(returnText)
  }

  stripTags(text, allowed) {
    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    return text.replace(tags, ($0, $1) => (allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''))
  }

  autoLink(text) {
    const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    const regexp_makeLink = (all, url, h, href) => {
      const target = href.indexOf('beforester.net') > 0 ? '' : '';
      if (href.toLowerCase().indexOf('javascript:') > 0) return url;
      return '<a href="h' + href + '" class="link-color" ' + target + 'rel="nofollow noopener noreferrer">' + url + '</a>';
    }

    return text.replace(regexp_url, regexp_makeLink);
  }

  loadingCheck() {
    const chatStatus = document.getElementById('chatStatus');
    if (chatStatus.dataset.loading == 'true') {
      chatStatus.dataset.loading = 'false'
      this.fetchMessages();
    }
  }

  componentDidMount() {
    this.fetchMessages();
    this.loadingCheck();

    // 1秒に1回チェック
    this.timerID = setInterval(() => {
      this.loadingCheck();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    let messages = this.state.messages.map((message, key) => <div className="message" key={key}>
      <div className="message__icon" style={{ backgroundImage: `url(${message.user_icon})` }}></div>
      <div className="message__baloon">
        <div className="message__baloon">
          <div className="flex items-center">
            <h2 className="message__user-name"><a href={`/users/${message.user.public_id}`}>{message.user.name}</a></h2>
            <p className="message__date">{message.display_datetime}</p>
          </div>
          <p className="message__content" dangerouslySetInnerHTML={{ __html: this.setMessage(message.content) }}></p>
        </div>
      </div>
    </div>);

    return <div className="room show">
      <Form
        baseURI={this.props.baseURI}
        uid={this.props.uid}
        roomId={this.props.roomId}
        fetchMessages={() => { this.fetchMessages(); }}
      />
      <div className="messages">
        {messages}
      </div>
    </div>;
  }
}

chatBodyShow.propTypes = {
  baseURI: PropTypes.string,
  uid: PropTypes.string,
  roomId: PropTypes.string,
};
export default chatBodyShow
