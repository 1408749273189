import React from "react";

class chatButton extends React.Component {
  state = {
    count: 0,
  };

  checkCount() {
    const STATUS = document.getElementById("chatStatus");
    this.setState({
      count: Number(STATUS.dataset.count),
    });
  }

  componentDidMount() {
    this.checkCount();

    // 1秒に1回チェック
    this.timerID = setInterval(() => {
      this.checkCount();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const COUNT =
      this.state.count > 0 ? (
        <p className="count">
          {this.state.count > 99 ? "99+" : this.state.count}
        </p>
      ) : (
        ""
      );

    return (
      <div className="chat-button" onClick={openModal.bind(this)}>
        <span className="icon"></span>
        <p className="text">メッセージ</p>
        {COUNT}
      </div>
    );
  }
}

chatButton.propTypes = {};
export default chatButton;
